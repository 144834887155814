import { Image, Table } from "react-bootstrap";

const avatarWidth = {
  maxWidth: "160px",
  margin: "auto",
  marginBottom: "1.5rem",
};

function ResultComponent(props) {
  return (
    <div className="mt-4">
      <div style={avatarWidth}>
        <Image
          fluid={true}
          className="rounded-4"
          src={props.student.imageUrl}
          alt={props.student.name}
        />
      </div>
      <Table>
        <tbody>
          <tr>
            <th>Student ID</th>
            <td>{props.student.id}</td>
          </tr>
          <tr>
            <th>Student Name</th>
            <td>{props.student.name}</td>
          </tr>
          <tr>
            <th>Fathers Name</th>
            <td>{props.student.fathers}</td>
          </tr>
          <tr>
            <th>Mothers Name</th>
            <td>{props.student.mothers}</td>
          </tr>
          <tr>
            <th>Address</th>
            <td>{props.student.address}</td>
          </tr>
          <tr>
            <th>Course Name</th>
            <td>{props.student.course}</td>
          </tr>
          <tr>
            <th>Duration</th>
            <td>{props.student.duration}</td>
          </tr>
          <tr>
            <th>Batch No</th>
            <td>{props.student.batch}</td>
          </tr>
          <tr>
            <th>Batch Status</th>
            <td>{props.student.status}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default ResultComponent;
