import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";
import { Route, Routes } from "react-router-dom";
import TopbarComponent from "./components/TopbarComponent";
import NavbarComponent from "./components/NavbarComponent";

import Home from "./components/HomeComponent";
import Verify from "./components/VerifyComponent";

import FooterComponent from "./components/FooterComponent";
import CopyrightComponent from "./components/CopyrightComponent";
import NotFountComponent from "./components/NotFoundComponent";

function App() {
  return (
    <>
      <TopbarComponent />
      <NavbarComponent />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<Home />} />
        <Route path="/courses" element={<Home />} />
        <Route path="/freelancing" element={<Home />} />
        <Route path="/gallery" element={<Home />} />
        <Route path="/contact" element={<Home />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="*" element={<NotFountComponent />} />
      </Routes>
      <FooterComponent />
      <CopyrightComponent />
    </>
  );
}

export default App;
