import styles from "../css/Header.module.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import dot from "../images/dot.svg";
import photo from "../images/photo.png";

function HomeHeaderComponent() {
  return (
    <header className={styles.headerBg}>
      <div className={styles.bg1}></div>
      <Container>
        <Row className="align-items-center">
          <Col md={6} className={styles.headerText}>
            <div>
              <h3>অবসরে বেকার বসে না থেকে</h3>
              <h2>
                আইটি সেক্টরে <span>স্কিল ডেভোলপমেন্ট হতে পারে</span> সময়ের সেরা
                সিদ্ধান্ত
              </h2>
              <p>
                অভিজ্ঞ মেন্টর আর আপডেটেড কারিকুলাম নিয়ে আল-মদিনা আইটি
                ইনস্টিটিউট প্রস্তুত আপনার ক্যারিয়ার গড়ার অগ্রযাত্রায়। আমাদের
                ১০টিরও বেশি ট্রেন্ডি কোর্স থেকে আজই বেছে নিন আপনার পছন্দের
                কোর্স।
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className="text-center position-relative">
              <Image className={styles.dot} src={dot} alt="Dot" />
              <Image
                fluid={true}
                className={styles.photo}
                src={photo}
                alt="Photo"
              />
              <div className={`${styles.jobReady} box-shadow`}>
                JOB-READY <br /> TRAINING
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default HomeHeaderComponent;
