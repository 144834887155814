import styles from "../css/Navbar.module.css";
import { NavLink } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import logo from "../images/logo-dark.svg";

function NavbarComponent() {
  return (
    <Navbar className={`${styles.navbarLight} ${styles.navbar}`} expand="lg">
      <Container>
        <NavLink className="navbar-brand" to="/">
          <img src={logo} alt="AL MADINA IT LOGO" height={40} />
        </NavLink>
        <Navbar.Toggle aria-controls="navbarTop" />
        <Navbar.Collapse id="navbarTop">
          <Nav as="ul" className="ms-auto mb-2 mb-lg-0">
            <Nav.Item as="li" className={styles.navItem}>
              <NavLink className="nav-link" to="/">
                হোম
              </NavLink>
            </Nav.Item>
            <Nav.Item as="li" className={styles.navItem}>
              <NavLink className="nav-link" to="/about-us">
                আমাদের সম্পর্কে
              </NavLink>
            </Nav.Item>
            <Nav.Item as="li" className={styles.navItem}>
              <NavLink className="nav-link" to="/courses">
                কোর্স সমূহ
              </NavLink>
            </Nav.Item>
            <Nav.Item as="li" className={styles.navItem}>
              <NavLink className="nav-link" to="/freelancing">
                ফ্রিল্যান্সিং
              </NavLink>
            </Nav.Item>
            <Nav.Item as="li" className={styles.navItem}>
              <NavLink className="nav-link" to="/gallery">
                গ্যালারি
              </NavLink>
            </Nav.Item>
            <Nav.Item as="li" className={styles.navItem}>
              <NavLink className="nav-link" to="/contact">
                যোগাযোগ
              </NavLink>
            </Nav.Item>
          </Nav>
          <div className="d-flex px-3">
            <NavLink className="btn-brand py-1 px-3 rounded" to="/verify">
              Verify
            </NavLink>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
