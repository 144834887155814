import styles from "../css/SuccessStory.module.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import dot from "../images/dot.svg";
import studentPhoto from "../images/student.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function SuccessStoryComponent() {
  return (
    <section className={styles.successStory}>
      <Container>
        <Row className="text-center">
          <Col md={12} className="mt-5">
            <h2 className={styles.successTitle}>
              সফলতার <span>১১ বছর</span>
            </h2>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={6} xs={6} className="d-flex align-self-stretch">
            <div className={`${styles.successItem} box-shadow`}>
              <h4>
                <span>৭০০০</span>+
              </h4>
              <p>শিক্ষার্থী নিয়েছেন প্রফেশনাল ট্রেনিং</p>
            </div>
          </Col>
          <Col md={3} sm={6} xs={6} className="d-flex align-self-stretch">
            <div className={`${styles.successItem} box-shadow`}>
              <h4>
                <span>৩৫০০</span>+
              </h4>
              <p>সফল ফ্রিল্যান্সার ও উদ্যোক্তা</p>
            </div>
          </Col>
          <Col md={3} sm={6} xs={6} className="d-flex align-self-stretch">
            <div className={`${styles.successItem} box-shadow`}>
              <h4>
                <span>১০০</span>+
              </h4>
              <p>মেন্টর কাজ করছেন বিভিন্ন প্রতিষ্ঠানে</p>
            </div>
          </Col>
          <Col md={3} sm={6} xs={6} className="d-flex align-self-stretch">
            <div className={`${styles.successItem} box-shadow`}>
              <h4>
                <span>৯০</span>%
              </h4>
              <p>সাফল্য অনুপাত</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="bg-light-1 p-4 mt-5 rounded-5">
        <Row>
          <Col md={6}>
            <Image
              fluid={true}
              className="rounded-4"
              src={studentPhoto}
              alt="Student Photo"
            />
          </Col>
          <Col md={6} className="position-relative">
            <div className={styles.story}>
              <h3 className={`${styles.storyTitle} cyan mt-3`}>সফলতার গল্প</h3>
              <p className={styles.desc}>
                আমাদের শিক্ষার্থীদের সফলতার প্রতিটি গল্প আল মদিনা আইটি
                ইন্সটিটিউটের এগিয়ে চলায় উদ্দীপনা যোগায় প্রতিটি মুহূর্তে।
              </p>
              <div className={styles.star}>
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
              </div>
              <hr />
              <p className={styles.desc}>
                আল মদিনা আইটি-র জব প্লেসমেন্ট ডিপার্ট্মেন্ট দক্ষ শিক্ষার্থীদের
                কাজ খুঁজে দিতে কাজ করে থাকে। এরই ধারাবাহিকতায়, কোর্স চলাকালীন
                বা কোর্স শেষ করে স্বনামধন্য বিভিন্ন প্রতিষ্ঠানে কাজের সুযোগ
                পেয়েছেন প্রায় ৭৫ শতাংশ ছাত্র/ছাত্রী। তাই আল মদিনা আইটির
                শিক্ষার্থীদের ক্যারিয়ার যাত্রা এখন আরও সহজ।
              </p>
            </div>
            <div className={styles.dots}>
              <Image fluid={true} className="w-100" src={dot} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default SuccessStoryComponent;
