import styles from "../css/Courses.module.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import OfficeIcon from "../images/icon/office.svg";
import graphicIcon from "../images/icon/graphic_design.svg";
import digitalmarketingIcon from "../images/icon/digital_marketing.svg";
import hardwareIcon from "../images/icon/hardware.svg";
import videoIcon from "../images/icon/video_editing.svg";
import webIcon from "../images/icon/web_development.svg";

function CoursesComponent() {
  return (
    <section className={styles.courses}>
      <Container>
        <Row>
          <Col
            md={2}
            xs={6}
            className="d-flex align-self-stretch transform-top mt-0"
          >
            <div className={`${styles.courseItem} box-shadow`}>
              <div className={styles.icon}>
                <Image src={OfficeIcon} />
              </div>
              <h3>প্রফেশনাল অফিস অ্যাপ্লিকেশন</h3>
            </div>
          </Col>
          <Col
            md={2}
            xs={6}
            className="d-flex align-self-stretch transform-top mt-0"
          >
            <div className={`${styles.courseItem} box-shadow`}>
              <div className={styles.icon}>
                <Image src={graphicIcon} />
              </div>
              <h3>গ্রাফিক ডিজাইন এন্ড ফ্রিল্যান্সিং</h3>
            </div>
          </Col>
          <Col
            md={2}
            xs={6}
            className="d-flex align-self-stretch transform-top mt-0"
          >
            <div className={`${styles.courseItem} box-shadow`}>
              <div className={styles.icon}>
                <Image src={webIcon} />
              </div>
              <h3>ফুলস্ট্যাক ওয়েব ডেভোলপমেন্ট</h3>
            </div>
          </Col>
          <Col
            md={2}
            xs={6}
            className="d-flex align-self-stretch transform-top mt-0"
          >
            <div className={`${styles.courseItem} box-shadow`}>
              <div className={styles.icon}>
                <Image src={videoIcon} />
              </div>
              <h3>ভিডিও এডিটিং এন্ড ইউটিউব মাকের্টিং</h3>
            </div>
          </Col>
          <Col
            md={2}
            xs={6}
            className="d-flex align-self-stretch transform-top mt-0"
          >
            <div className={`${styles.courseItem} box-shadow`}>
              <div className={styles.icon}>
                <Image src={digitalmarketingIcon} />
              </div>
              <h3>ভিডিও এডিটিং এন্ড ইউটিউব মাকের্টিং</h3>
            </div>
          </Col>
          <Col
            md={2}
            xs={6}
            className="d-flex align-self-stretch transform-top mt-0"
          >
            <div className={`${styles.courseItem} box-shadow`}>
              <div className={styles.icon}>
                <Image src={hardwareIcon} />
              </div>
              <h3>ভিডিও এডিটিং এন্ড ইউটিউব মাকের্টিং</h3>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default CoursesComponent;
