import styles from "../css/Footer.module.css";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

function JoinNow() {
  return (
    <Row className="justify-content-md-center transform-top">
      <Col md={9}>
        <Row className={`${styles.joinNow} d-flex`}>
          <Col xs={8} className="text-start">
            <h4>
              Join our student <br />
              community
            </h4>
          </Col>
          <Col xs={4} className={styles.btnJoin}>
            <Link
              className="btn btn-dark btn-lg"
              to="https://www.facebook.com/groups/770242229724074"
              target="_blank"
            >
              Join Now
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default JoinNow;
