import styles from "../css/Topbar.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

function TopbarComponent() {
  return (
    <div className={styles.topbar}>
      <Container>
        <Row>
          <Col md={6}>All course 28% off for Liberian people’s.</Col>
          <Col md={3}>
            <FontAwesomeIcon icon={faPhone} />
            <a className="px-2" href="tel:+8801736722622">
              +8801736722622
            </a>
          </Col>
          <Col md={3}>
            <FontAwesomeIcon icon={faEnvelope} />
            <a className="px-2" href="mailto:almadinait@gmail.com">
              almadinait@gmail.com
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TopbarComponent;
