import styles from "../css/PracticalProject.module.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import dot from "../images/dot.svg";
import practicalPhoto from "../images/Cyber-Security.jpg";

function PracticalProjectComponent() {
  return (
    <section className={styles.practicalProject}>
      <Container className={`${styles.custom} bg-light-3 rounded-5`}>
        <Row>
          <Col md={6} className="position-relative">
            <Image
              fluid={true}
              className="rounded-5 z-index-2"
              src={practicalPhoto}
              alt="Practical Project"
            />
            <div className={styles.dots}>
              <Image fluid={true} className="img-fluid w-100" src={dot} />
            </div>
          </Col>
          <Col md={6} className={`${styles.text}`}>
            <h3 className={`${styles.projectTitle} mt-2`}>
              <span>প্র্যাকটিক্যাল প্রজেক্টের মাধ্যমে</span>
              <br />
              শিক্ষাদান আমাদের বিশেষত্ব
            </h3>
            <p className={styles.desc}>
              প্র্যাকটিক্যাল প্রোজেক্ট ছাড়া যেকোনো শিক্ষা অপূর্ণ। এক জরিপে দেখা
              গেছে, কেবল হাতে-কলমে শিক্ষার অভাবে চাকরি জীবনে ভোগান্তির শিকার হন
              ৬৭ শতাংশ মানুষ। তাছাড়া মার্কেটপ্লেস বা লোকাল জবে একটি পোর্টফোলিও
              না থাকলে কাজ পেতেও অনেক সময় লাগে। সব ক্যারিয়ারের শুরুতে কম-বেশি
              সবাই এই সমস্যায় ভোগেন। তাই আল-মদিনা আইটি বিশ্বাস করে হাতে-কলমে
              শিক্ষার উপর। এজন্যই আমাদের প্রতিটি কোর্সে রাখা হয়েছে পর্যাপ্ত
              সংখ্যক প্রজেক্ট। আপনি কোর্স চলাকালীন সময়েই নিজের একটি ভালো
              পোর্টফোলিও তৈরি করতে পারবেন।
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default PracticalProjectComponent;
