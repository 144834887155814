import styles from "../css/Partner.module.css";
import { Container, Row, Col } from "react-bootstrap";
import anchorPoint from "../images/client/anchorpoint.jpg";
import aptechEng from "../images/client/aptech-eng.jpg";
import aptech from "../images/client/aptech.jpg";
import eshikhon from "../images/client/eshikhon.jpg";
import fmMethod from "../images/client/fm-method.jpg";
import fracademy from "../images/client/fracademy.jpg";
import graphicschool from "../images/client/graphicschool.jpg";
import guardian from "../images/client/guardian.jpg";
import infotech from "../images/client/infotech.jpg";
import variant from "../images/client/variant.jpg";
import youthict from "../images/client/youthict.jpg";
import zamzam from "../images/client/zamzam.jpg";

function PartnerComponent() {
  return (
    <section className={styles.ourPartner}>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={9}>
            <h3 className={styles.partnerTitle}>
              আমাদের <span>পার্টনারসমূহ</span>
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={3} xs={6}>
            <div className={styles.partnerLogo}>
              <img src={aptech} alt="Aptech" />
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className={styles.partnerLogo}>
              <img src={fmMethod} alt="FM Method" />
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className={styles.partnerLogo}>
              <img src={eshikhon} alt="EShikhon" />
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className={styles.partnerLogo}>
              <img src={youthict} alt="Youth ICT" />
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className={styles.partnerLogo}>
              <img src={infotech} alt="Infotech" />
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className={styles.partnerLogo}>
              <img src={anchorPoint} alt="Anchor Point" />
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className={styles.partnerLogo}>
              <img src={fracademy} alt="Freelancing Academy" />
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className={styles.partnerLogo}>
              <img src={variant} alt="Variant Logo" />
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className={styles.partnerLogo}>
              <img src={guardian} alt="Guardian Network" />
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className={styles.partnerLogo}>
              <img src={zamzam} alt="ZamZam Graphics" />
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className={styles.partnerLogo}>
              <img src={aptechEng} alt="Aptech English" />
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className={styles.partnerLogo}>
              <img src={graphicschool} alt="Graphic School" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default PartnerComponent;
