import { Link } from "react-router-dom";

function CopyrightComponent() {
  return (
    <div className="copyright">
      <p>
        Copyright © 2022 <Link to="/">Al-Madina IT Institute.</Link> All right
        reserved
      </p>
    </div>
  );
}

export default CopyrightComponent;
