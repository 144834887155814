import styles from "../css/Verify.module.css";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import ResultComponent from "./ResultComponent";
import { useState } from "react";
import Avatar from "../images/avatar.png";
import { PuffLoader } from "react-spinners";

const override = {
  display: "block",
  margin: "0 auto",
};

const studentSchema = yup.object().shape({
  studentID: yup
    .number()
    .typeError("Student ID must be number")
    .required("Student ID is Required")
    .integer(),
  batch: yup
    .number()
    .typeError("Batch no must be number")
    .required("Batch no is Required")
    .integer(),
});
function Error(props) {
  return (
    <Alert className="mt-3" variant="danger">
      {props.error.error}
    </Alert>
  );
}
function Verify() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [error, setError] = useState(false);

  return (
    <section className={`${styles.verify} bg-light`}>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={9}>
            <h2 className={styles.verifyTitle}>
              Our Student <span>Verification</span>
            </h2>
            <Formik
              initialValues={{
                studentID: "",
                batch: "",
              }}
              validationSchema={studentSchema}
              onSubmit={(values) => {
                setResult(false);
                setError(false);
                setLoading(true);
                const url = `https://app.almadinait.com/api/student?id=${values.studentID}&batch=${values.batch}`;
                fetch(url)
                  .then((response) => response.json())
                  .then((data) => {
                    if (data.hasOwnProperty("error")) {
                      setLoading(false);
                      setError({ error: data.error });
                    } else {
                      setLoading(false);
                      let avatar;
                      if (data.student.avatar) {
                        avatar = `https://app.almadinait.com/students/${data.student.avatar}`;
                      }
                      setResult({
                        imageUrl: avatar || Avatar,
                        id: data.student.id,
                        name: data.student.name,
                        fathers: data.student.fathers_name,
                        mothers: data.student.mothers_name,
                        address: data.student.present_address,
                        course: data.student.course_name,
                        duration: data.student.duration,
                        batch: data.student.batch,
                        status: data.student.batch_status,
                        date: data.student.date,
                      });
                    }
                  })
                  .catch((error) => {
                    setLoading(false);
                    setError({ error: error });
                  });
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="studentID">
                    <Form.Label>Student ID</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter Student ID"
                      name="studentID"
                      value={values.studentID}
                      onChange={handleChange}
                      isInvalid={!!errors.studentID}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.studentID}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="batchNo">
                    <Form.Label>Batch Number</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter Batch Number"
                      name="batch"
                      value={values.batch}
                      onChange={handleChange}
                      isInvalid={!!errors.batch}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.batch}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button variant="success" type="submit">
                    Verify
                  </Button>
                </Form>
              )}
            </Formik>
            <PuffLoader
              color="#36d7b7"
              loading={loading}
              cssOverride={override}
              size={100}
            />
            {(function () {
              if (result) {
                return <ResultComponent student={result} />;
              } else if (error) {
                return <Error error={error} />;
              }
            })()}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Verify;
