import styles from "../css/NewBatch.module.css";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function NewCoursesComponent({ imageUrl, title, subTitle, courseFee }) {
  return (
    <div className={styles.item}>
      <Image fluid={true} className="rounded-4" src={imageUrl} alt={title} />
      <div className={styles.courseText}>
        <h4>{title}</h4>
        <p>{subTitle}</p>
        <div className={styles.star}>
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
        </div>
        <p className={styles.courseFee}>কোর্স ফি {courseFee}৳</p>
      </div>
    </div>
  );
}

export default NewCoursesComponent;
