import HomeHeaderComponent from "./HomeHeaderComponent";
import CoursesComponent from "./CoursesComponent";
import CareerComponent from "./CareerComponent";
import SuccessStoryComponent from "./SuccessStoryComponent";
import NewBatchComponent from "./NewBatchComponent";
import PracticalProjectComponent from "./PracticalProjectComponent";
import PartnerComponent from "./PartnerComponent";

function Home() {
  return (
    <>
      <HomeHeaderComponent />
      <CoursesComponent />
      <CareerComponent />
      <SuccessStoryComponent />
      <NewBatchComponent />
      <PracticalProjectComponent />
      <PartnerComponent />
    </>
  );
}

export default Home;
