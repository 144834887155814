import styles from "../css/Footer.module.css";
import { Link, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import JoinNow from "./JoinComponent";

function FooterComponent() {
  return (
    <footer>
      <Container>
        <Routes>
          <Route path="/" element={<JoinNow />} />
        </Routes>
        <Row>
          <Col md={4}>
            <h3>যোগাযোগ</h3>
            <ul>
              <li className={styles.location}>
                অফিস : <br />
                # ফখরে বাঙ্গাল রোড, <br />
                কান্দিপাড়া, ব্রাহ্মণবাড়িয়া ৩৪০০
              </li>
              <li className={styles.phone}>
                <Link to="tel:+8801736722622">+৮৮০ ১৭৩৬ ৭২২৬২২</Link>
                <br />
                <Link to="tel:+8801976722611">+৮৮০ ১৯৭৬ ৭২২৬১১</Link>
              </li>
              <li className={styles.email}>
                <Link to="mailto:almadinait@gmail.com">
                  almadinait@gmail.com
                </Link>
                <br />
                <Link to="mailto:almadinait.bd@gmail.com">
                  almadinait.bd@gmail.com
                </Link>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <h3>কোর্স সমূহ</h3>
            <ul>
              <li>
                <Link to="#">অফিস অ্যাপ্লিকেশন</Link>
              </li>
              <li>
                <Link to="#">গ্রাফিক ডিজাইন ও ফ্রিল্যান্সিং</Link>
              </li>
              <li>
                <Link to="#">ফুলস্টাক ওয়েব ডেভোলপমেন্ট</Link>
              </li>
              <li>
                <Link to="#">ভিডিও এডিটিং ও মোশন গ্রাফিক্স</Link>
              </li>
              <li>
                <Link to="#">হার্ডরওয়্যার এন্ড নেটওয়ার্কিং</Link>
              </li>
              <li>
                <Link to="#">অটোক্যাড</Link>
              </li>
              <li>
                <Link to="#">এডভান্স এক্সেল ও পাওয়ারপয়েন্ট</Link>
              </li>
              <li>
                <Link to="#">স্পোকেন ইংলিশ</Link>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <h3>Google Loaction</h3>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default FooterComponent;
