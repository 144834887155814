import styles from "../css/Career.module.css";
import { Container, Row, Col } from "react-bootstrap";
import quote from "../images/quote.svg";

function CareerComponent() {
  return (
    <section className={`${styles.itCareer} bg-light`}>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={9}>
            <h2 className={styles.careerTitle}>
              কেন আমরা <span>সবার চেয়ে এগিয়ে</span>
            </h2>
            <p className={styles.careerDecs}>
              দেশ ও দেশের বাইরে বর্তমানে যে স্কিলগুলোর চাহিদা সবচেয়ে বেশি, সেসব
              দিয়েই সাজানো হয়েছে আমাদের কোর্স লিস্ট। এখান থেকে আপনার সুবিধামত
              অনলাইন বা অফলাইনে কোর্সে এনরোল করতে পারবেন যেকোনো সময়।
            </p>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={3}>
            <div className={`${styles.career} box-shadow`}>
              <img className="pt-3" src={quote} alt="Quote" width="65px" />
              <p>
                স্কিল বৃদ্ধি থেকে শুরু করে ক্যারিয়ার ডেভেলপমেন্ট নিজেকে
                মানসম্পন্ন করে তোলার এ-টু-জেড সিলেবাস
              </p>
            </div>
          </Col>
          <Col md={3}>
            <div className={`${styles.career} box-shadow`}>
              <img className="pt-3" src={quote} alt="Quote" width="65px" />
              <p>
                দক্ষ প্রশিক্ষকগণ সবচেয়ে সহজ উপায়ে কোর্স মডিউল তৈরি ও লেকচার
                শীট প্রদান
              </p>
            </div>
          </Col>
          <Col md={3}>
            <div className={`${styles.career} box-shadow`}>
              <img className="pt-3" src={quote} alt="Quote" width="65px" />
              <p>
                মেধা যাচাইয়ের জন্য কুইজ ও এক্সামের ব্যবস্থা। কোর্স শেষে সনদ
                প্রদান। এবং লাইফ টাইম মেম্বারশীপ
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default CareerComponent;
