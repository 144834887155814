import styles from "../css/NewBatch.module.css";
import { Container, Row, Col } from "react-bootstrap";
import NewCoursesComponent from "./NewCoursesComponent";
import spokenPhoto from "../images/Spoken-English.jpg";
import graphicsPhoto from "../images/Graphic-Design.jpg";
import webPhoto from "../images/Web-Design.jpg";

function NewBatchComponent() {
  return (
    <section className={styles.newBatch}>
      <Container>
        <Row className="text-center">
          <Col md={12}>
            <h2 className={styles.batchTitle}>
              নতুন ব্যাচ <span>শুরু</span>
            </h2>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <NewCoursesComponent
              imageUrl={spokenPhoto}
              title="অফিস অ্যাপ্লিকেশন"
              subTitle="বেসিক থেকে এডভান্স"
              courseFee="৬০০০"
            />
          </Col>
          <Col md={4}>
            <NewCoursesComponent
              imageUrl={graphicsPhoto}
              title="গ্রাফিক ডিজাইন ও ফ্রিল্যান্সিং"
              subTitle="বেসিক থেকে এডভান্স"
              courseFee="১২০০০"
            />
          </Col>
          <Col md={4}>
            <NewCoursesComponent
              imageUrl={webPhoto}
              title="ওয়েব ডেভেলপমেন্ট"
              subTitle="বেসিক থেকে এডভান্স"
              courseFee="২০০০০"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default NewBatchComponent;
